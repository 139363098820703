import React, { useEffect, useState } from "react";

const VideoWithLoader = ({ src, type, index }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleCanPlay = () => setLoading(false);
  const handleError = () => { setLoading(false); setError(true); };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => {
  const video = document.getElementById(`video${index}`);
  if (video) {
    video.load(); // Force reload if needed
  }
}, [src, index]);


  const playIconStyle = {
    ...styles.playIcon,
    background: windowWidth <= 768 ? "rgb(14 146 234 / 0%)" : "rgba(14, 146, 234, 0.5)",
    color: windowWidth <= 768 ? "#ffffff00" : "white",
  };

  const togglePlayPause = (videoRef) => {
    if (videoRef) {
      if (videoRef.paused) {
        videoRef.play();
        setIsPlaying(true);
      } else {
        videoRef.pause();
        setIsPlaying(false);
      }
    }
  };

  return (
    <div style={styles.videoContainer}>
      {loading && !error && <div className="spinner-border text-primary" role="status" style={{ width: '50px', height: '50px', top: "40%", left: "43%" }}>
        <span className="sr-only">Loading...</span>
      </div>}
      {error ? (
        <div style={styles.errorText}>Failed to load video</div>
      ) : (
        <>

          <video
            style={{ ...styles.video, display: loading ? "none" : "block" }}
            controls
            playsInline  // Critical for iOS Safari
            webkit-playsinline="true"  // Legacy for older iOS
            x-webkit-airplay="allow"  // For AirPlay support
            preload="metadata"  // Better for Safari loading
            onCanPlay={handleCanPlay}
            onError={handleError}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            id={`video${index}`}
            title="Video"
            muted={true}  // Required for autoplay in most browsers
            className="h-100 w-100"
          >
            {/* Optimized source order for Safari */}
            <source src={src} type={type || "video/mp4"} />
            {/* Safari works best with H.264 encoded MP4 */}
            Your browser does not support HTML5 video.
          </video>
          {/* <video
            style={{ ...styles.video, display: loading ? "none" : "block" }}
            controls
            onCanPlay={handleCanPlay}
            onError={handleError}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            id={`video${index}`}
            title="Video"
            muted
            className="h-100 w-100"
          >
            <source src={src} type={type} />
            <source src={`${src.replace('.mp4', '.webm')}`} type="video/webm" />
            <source src={`${src.replace('.mp4', '.ogv')}`} type="video/ogg" />
            Your browser does not support the video tag.
          </video> */}
          {!isPlaying && !loading && (
            <div
              style={playIconStyle}
              onClick={() => togglePlayPause(document.getElementById(`video${index}`))}
            >
              ▶
            </div>
          )}
        </>
      )}
    </div>
  );
};

const styles = {
  videoContainer: {
    position: "relative",
    height: "250px",
    background: "#000",
  },
  video: {
    width: "100%",
    height: "100%",
  },
  playIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontSize: "20px",
    borderRadius: "50%",
    padding: "18px 23px",
    cursor: "pointer",
    zIndex: 2,
  },
  errorText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    fontSize: "18px",
    textAlign: "center",
  },
};

export default VideoWithLoader;
