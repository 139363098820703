import React, { useState } from 'react'
import Loginmodals from '../../Mymodals/Loginmodals'
import ImagePopup from '../../Mymodals/ImagePopup';
import ProductPopup from '../../Mymodals/ProductPopup';

export default function Products({ data, webpreview }) {
    const [isopen, setIsopen] = useState(false);
    const [img, setImg] = useState();
    const isClose = () => { setIsopen(false); }


    const imgPopupHndler = (data) => {
        setImg(data);
        setIsopen(true);
    }
    return (
        <>
            {/* Products */}
            <section id="Products">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="section-title text-center">
                                <h2 style={{ color: `${webpreview && webpreview.Header_Fonts ? webpreview.Header_Fonts : ""}` }}>Products</h2>
                                <small className='myline'></small>
                                <p>{webpreview && webpreview.prd_description ? webpreview.prd_description : "Invitamus me testatur sed"}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row" >
                        <ul className="products" style={{display:'flex', justifyContent:"center"}}>
                            {data && data.length > 0 &&
                                data.map((e, i) => (
                                    <li className="wow fadeInUp" key={i} onClick={() => imgPopupHndler(e)} style={{ cursor: "pointer" }}>
                                        <div className="product-thumb">
                                            <img src={e.product_images[0] ? e.product_images[0].image_name : "img/equipment/1.jpg"} alt="" style={{ minHeight: '320px', maxHeight: '320px' }} />
                                            <div className="product-overlay">
                                                <div className="overlay-inner">
                                                </div>
                                            </div>
                                            <div className="product-info text-center">
                                                <span className="product-price">$ {e.product_price}</span>
                                                <h3>  {e.product_name} </h3>

                                            </div>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </section>
            <ProductPopup isopen={isopen} isClose={isClose} e={img} webpreview={webpreview} />
            {/* <Loginmodals opens={isopen} isClose={isClose}  /> */}
            {/* End Products */}
        </>
    )
}
