import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";

const SliderComponent = ({ slides, maindata }) => {
    const swiperRef = useRef(null);
    const videoRefs = useRef([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(null);
    const [scrollPositionValue, setScrollPositionValue] = useState(0);
    const [windowHeightValue, setWindowHeightValue] = useState(0);

    const handleCanPlay = () => {
        setLoading(false);
    };

    const handleError = () => {
        setLoading(false);
        setError(true);
    };

    const hexToRgba = (hex, alpha = 1) => {
        let r = 0, g = 0, b = 0;

        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        } else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };

    const togglePlayPause = () => {
        if (currentVideo) {
            if (currentVideo.paused) {
                currentVideo.play()
                    .then(() => {
                        setIsPlaying(true);
                    })
                    .catch((error) => {
                        console.error("Error playing video:", error);
                    });
            } else {
                currentVideo.pause();
                setIsPlaying(false);
            }
        }
    };

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const windowHeight = window.innerHeight;
        setScrollPositionValue(scrollPosition);
        setWindowHeightValue(windowHeight);
        if (currentVideo && !currentVideo.paused) {
            if (scrollPosition > windowHeight * 0.5) {
                currentVideo.muted = true;
            } else {
                currentVideo.muted = false;
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [currentVideo]);

    useEffect(() => {
        const updateHeight = () => {
            if (window.innerWidth <= 768) {
                document.querySelectorAll("video, #banner").forEach(el => {
                    el.style.height = "100%";
                });
            } else {
                document.querySelectorAll("video, #banner").forEach(el => {
                    el.style.height = "100vh";
                });
            }
        };
    
        window.addEventListener("resize", updateHeight);
        updateHeight();
    
        return () => window.removeEventListener("resize", updateHeight);
    }, []);
    

    useEffect(() => {
        const swiper = swiperRef.current.swiper;
        const slideChangeHandler = () => {
            const currentSlideIndex = swiper.realIndex;
            if (slides && slides.length > 0) {
                const currentSlide = slides[currentSlideIndex];
                if (currentVideo) {
                    currentVideo.pause();
                }
                if (currentSlide.file_type === "video") {
                    const video = videoRefs.current[currentSlideIndex];
                    setCurrentVideo(video);
                    if (video) {
                        video.currentTime = 0; // Reset video time
                        video.muted = false; // Try to play with sound first
                        video.play()
                            .then(() => {
                                console.log("Video started playing with sound");
                                if (scrollPositionValue > windowHeightValue * 0.5) {
                                    video.muted = true;
                                } else {
                                    video.muted = false;
                                }
                                swiper.autoplay.stop();
                                video.onended = () => {
                                    console.log("Video ended, moving to next slide...");
                                    swiper.slideNext();
                                    swiper.autoplay.start();
                                };
                            })
                            .catch(error => {
                                console.error("Autoplay with sound failed. Trying muted autoplay:", error);
                                video.muted = true; // Fallback to muted autoplay
                                video.play()
                                    .then(() => {
                                        console.log("Video started playing muted");
                                        swiper.autoplay.stop();
                                        video.onended = () => {
                                            console.log("Video ended, moving to next slide...");
                                            swiper.slideNext();
                                            swiper.autoplay.start();
                                        };
                                    })
                                    .catch(error => {
                                        console.error("Muted autoplay also failed. User interaction required:", error);
                                        setIsPlaying(false); // Show play button
                                    });
                            });
                    }
                } else {
                    setCurrentVideo(null);
                    swiper.params.autoplay.delay = 5000;
                    swiper.autoplay.start();
                    swiper.muted = false;
                }
            }
        };
        swiper.on("slideChange", slideChangeHandler);
        slideChangeHandler();
        return () => swiper.off("slideChange", slideChangeHandler);
    }, [slides, currentVideo]);

    return (
        <Swiper
            ref={swiperRef}
            modules={[Autoplay]}
            autoplay={{ delay: 5000, disableOnInteraction: false }}
            loop={true}
            className="w-full"
        >
            {slides?.map((slide, index) => (
                <SwiperSlide key={index} className="flex justify-center items-center">
                    {slide.file_type === "video" ? (
                        <div className="VideoContainer" style={{ ...styles.videoContainer, backgroundImage: `url(${slide.video_poster})` }}>
                            {loading && !error && <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>}
                            {error ? (
                                <div style={styles.errorText}>Failed to load video</div>
                            ) : (
                                <>
                                    <video
                                        ref={el => (videoRefs.current[index] = el)}
                                        className="w-full h-full object-cover"
                                        style={{ width: "100%", height: "100vh", objectFit: "cover", zIndex: '0' }}
                                        autoPlay
                                        muted // Ensure this is included
                                        playsInline // Prevent fullscreen on mobile
                                        poster={slide.video_poster}
                                        onCanPlay={handleCanPlay}
                                        onError={handleError}
                                        onPlay={() => setIsPlaying(true)}
                                        onPause={() => setIsPlaying(false)}
                                    >
                                        <source src={slide.brImage} type="video/mp4" />
                                        Your browser does not support the video tag.
                                    </video>
                                    {!isPlaying && !loading && (
                                        <div
                                            style={styles.playIcon}
                                            onClick={togglePlayPause}
                                        >
                                            ▶
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <div id="banner" style={{ backgroundImage: `url(${slide.brImage})`, height: "100vh" }} key={index}>
                            <div className="caption-info" >
                                <div className="container">
                                    <div className="row" >
                                        <div className="col-sm-12 col-md-8 col-md-offset-2"
                                            style={{
                                                background: maindata && maindata[`is_tr_color${1}`] === 'yes'
                                                    ? hexToRgba(maindata[`tr_color${1}`], maindata[`percentage${1}`] / 100)
                                                    : "", zIndex: 1,
                                            }}
                                        >
                                            <div className="parallax-bg-red py-4" style={{ position: "relative", zIndex: 999 }}>
                                                <div className="caption-info-inner text-center" >
                                                    {String(slide?.brTitle || '').length > 1 &&
                                                        <h1 className="animated fadeInDown" style={{
                                                            color:
                                                                maindata &&
                                                                    maindata["banner_font_color" + (index + 1)]
                                                                    ? maindata["banner_font_color" + (index + 1)]
                                                                    : "#fff",
                                                            display: slide?.brTitle ? 'block' : "none",
                                                        }}
                                                        >
                                                            {slide.brTitle}
                                                        </h1>
                                                    }
                                                    <div className="animated fadeInUp" style={{
                                                        color: maindata && maindata["banner_tagline" + (index + 1)]
                                                            ? maindata["banner_tagline" + (index + 1)]
                                                            : "#fff",
                                                        fontSize: "22px",
                                                        marginBottom: '0px'
                                                    }}
                                                    >
                                                        {slide.brSubtitle}
                                                        {slide.showbnlogo == "yes" &&
                                                            <div className="BrandLogo" >
                                                                <img
                                                                    src={
                                                                        maindata && maindata.logo
                                                                            ? maindata.logo
                                                                            : "img/logo.png"
                                                                    }
                                                                    className="BannerLogoImg"
                                                                    alt="Logo"
                                                                />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

const styles = {
    videoContainer: {
        position: "relative",
        height: "100vh",
        width: '100%',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },
    playIcon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "20px",
        color: "white",
        background: "rgba(14, 146, 234, 0.5)",
        borderRadius: "50%",
        padding: "18px 23px",
        cursor: "pointer",
        zIndex: 999,
    },
    errorText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "white",
        fontSize: "18px",
        textAlign: "center",
    },
};

export default SliderComponent;